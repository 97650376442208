<template>
  <div>
    <defaultFieldTypes
      :fieldAttributes="{
        ...fieldAttributes,
        fieldAttrInput: fieldAttrInput,
       
      }"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
    :value="localValue"></defaultFieldTypes>
  </div>
</template>

<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  components: { defaultFieldTypes },
  props: ["fieldAttributes", "field", "result","value"],
  computed: {
    fieldAttrInput() {
      let fieldAttrInput = {};
      if (this.result.overallFields.subscriberType != 1) {
        this.$set(fieldAttrInput, "readonly", true);
      }

      return fieldAttrInput;
    },
    localValue() {
      let value = this.value;
      if (this.result.overallFields.subscriberType != 1 && value != 1) {
        value = 1;
        this.$emit("input", 1);
      }

      return value;
    },
  },
};
</script>